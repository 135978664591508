@import "../../core/index";


.our_trust {
  margin: 60px auto 0 auto;
  background-color: #ECE8E1;
  display: flex;
  flex-direction: row;
  :global {
    .our_trust {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 38px;
      span, h3, h4 {
        font-family: brownmedium, serif;
        text-transform: uppercase;
      }
      h3 {
        font-size: 48px;
      }
      span, h4 {
        line-height: 19.98px;
        letter-spacing: 0.9px;
      }
      .ipad-mobile {
        display: none;
      }
      p {
        width: 62%;
        line-height: 25.04px;
        letter-spacing: 0.16px;
      }
    }
    .right_side {
      width: 50%;
      padding: 52px 129px 52px 101px;
      .container {
        position: relative;
        img {
          height: 100%;
          width: 100%;
        }
      }

    }
  }
}

@media only screen and (min-device-width : 768px)
and (max-device-width : 1200px)
and (orientation : landscape) {
  .our_trust {
    flex-direction: column;
    :global {
      .our_trust {
        margin-top: 60px;
        width: 100%;
        p {
          width: 85%;
        }
        .desktop {
          display: none;
        }
        .ipad-mobile {
          display: block;
        }
      }
      .right_side {
        width: 100%;
        padding: 60px 170px 60px 170px;
        .container {
          .text-6{
            right: 5%;
          }
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .our_trust {
    flex-direction: column;
    :global {
      .our_trust {
        margin-top: 60px;
        width: 100%;
        p {
          width: 85%;
        }
        .desktop {
          display: none;
        }
        .ipad-mobile {
          display: block;
        }
      }
      .right_side {
        width: 100%;
        padding: 60px 100px 60px 100px;
        .container {
          .text-1 {
            p {
              width: 70%;
            }
          }
          .text-6 {
            p {
              width: 70%;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .our_trust {
    flex-direction: column;
    :global {
      .our_trust {
        margin-top: 60px;
        width: 100%;
        p {
          width: 85%;
        }
        .desktop {
          display: none;
        }
        .ipad-mobile {
          display: block;
        }
      }
      .right_side {
        width: 100%;
        padding: 60px 120px 60px 120px;
        .container {
          .text {
            .text-6 {
              p {
                width: 60%;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .our_trust {
    flex-direction: column;
    :global {
      .our_trust {
        margin-top: 60px;
        width: 100%;
        gap: 30px;
        h3 {
          font-size: 32px;
        }
        p {
          width: 90%;
          font-size: 12px;
          line-height: 22px;
        }
      }
      .right_side {
        width: 100%;
        padding: 60px 10px 60px 10px;
        .container {
          .text {
            h3 {
              font-size: 7px;
            }
            p {
              font-size: 7px;
              margin-top: 3px;
              line-height: 10px;
            }
          }
          .text-1 {
            p {
              width: 80%;
            }
          }
          .text-2 {
            p {
              width: 70%;
            }
          }
          .text-4 {
            p {
              width: 70%;
            }
          }
          .text-5 {
            p {
              width: 70%;
            }
          }
          .text-6 {
            p {
              width: 80%;
            }
          }
        }
      }
    }
  }
}
