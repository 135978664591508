@import "../../core/index";

.header_banner {
  position: relative;
  width: 94.792%;
  margin: 0 2.604%;
  border-radius: 0;
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  overflow: hidden;
  .slider_div {
    height: 100%;
    width: 100%;
    aspect-ratio: 16/9;
    position: relative;
    img {
      position: relative !important;
      height: auto !important;
      aspect-ratio: 16/9;
    }
    .div_title_button {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      margin: auto;
      padding-left: 78px;
      display: flex;
      align-items: center;
      div {
        position: relative;
        display: flex;
        flex-flow: wrap;
        text-transform: uppercase;
        margin-top: 75px;
        .text_span {
          width: 100%;
          height: auto;
          :global{
            p{
              text-transform: uppercase;
              font-family: "brownlight", serif;
              font-size: 48px;
              line-height: 55px;
              letter-spacing: 0.1em;
              color: $primary;
              strong {
                font-family: "brownmedium", serif;
                color: $primary;
              }
              span{
                strong {
                  font-family: "brownmedium", serif;
                  color: $text-color-white;
                }
              }
            }
          }
        }
        .button_span {
          font-family: "brownregular", serif;
          font-size: 15px;
          height: 50px;
          margin-top: 35px;
          text-transform: uppercase;
          padding: 0 26.23px;
          background: $text-color-white;
          line-height: 15px;
          display: flex;
          align-items: center;
          letter-spacing: 0.05em;
          color: $primary;
          cursor: pointer;
          text-decoration: none;
          transition: all 300ms linear;
          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }
  .slider_pagination {
    align-items: flex-end;
    display: flex;
    justify-content: center;
    margin-bottom: 39px;
    .dot {
      cursor: pointer;
      background: #000;
      opacity: 0.2;
      border-radius: 8px;
      height: 8px;
      margin: 0 4px;
      width: 8px;
      &.active {
        background: #938985;
        opacity: 1;
        transition-delay: 0.4s;
      }
    }
    .dot_dark {
      background: #E1DDDA;
      opacity: 1;
      &.dot_dark_active {
        background: #938985;
        opacity: 1;
      }
    }
  }
  .slider_body {
    align-items: center;
    display: flex;
    flex: 1;
    text-align: center;
    .slider {
      flex: 1;
      align-self: stretch;
      position: relative;
      margin: 0 -16px;
      padding: 0;
      order: 2;
      .slide {
        position: absolute;
        list-style-type: none;
        height: 100%;
        width: 100%;
        opacity: 0;
        img {
          height: 100%;
          width: 100%;
        }
      }
      .active_image {
        opacity: 1;
        transform: translateX(0) scale(1);
        transition-delay: 0.4s;
        transition: all 0.5s;
      }
    }
  }
  .btn {
    background: rgba(87, 87, 89, 0.75);
    width: 50px;
    height: 89px;
    top: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    img {
      width: 12px;
      height: 12px;
    }
    &:hover {
      opacity: 1;
      transition: all 0.4s ease-in-out;
      filter: brightness(170%);
    }
  }
  .btn_previous {
    position: absolute;
    left: 0;
  }
  .btn_next {
    position: absolute;
    right: 0;
  }
}

@media screen and (max-width: 480px) {
  .header_banner {
    width: 100%;
    margin: 45px 0 0 0;
    .slider_div {
      .div_title_button {
        padding-left: 40px;
        div {
          margin-top: 0;
          padding-top: 14px;
          .text_span {
            :global{
              p{
                font-size: 16px;
                line-height: 24px;
              }
            }
          }
          .button_span {
            margin-top: 17px;
            padding: 0 18.23px;
            font-size: 10px;
            height: 35px;
          }
        }
      }
    }
    .slider_pagination {
      margin-bottom: 20px;
    }
    .btn {
      width: 30px;
      height: 50px;
      top: 37%;
      img {
        width: 9px;
        height: 9px;
      }
    }
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .header_banner {
    width: 100%;
    margin: 52px 0 0 0;
    .slider_div {
      .div_title_button {
        padding-left: 40px;
        div {
          margin-top: 0;
          padding-top: 0;
          .text_span {
            :global{
              p{
                font-size: 23px;
                line-height: 45px;
              }
            }
          }
          .button_span {
            margin-top: 5px;
            padding: 0 18.23px;
            font-size: 10px;
            height: 35px;
          }
        }
      }
    }
    .slider_pagination {
      margin-bottom: 25px;
    }
    .btn {
      width: 30px;
      height: 50px;
      top: 37%;
      img {
        width: 9px;
        height: 9px;
      }
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .header_banner {
    width: 100%;
    margin: 52px 0 0 0;
    .slider_div {
      .div_title_button {
        div {
          margin-top: 70px;
          .text_span {
            :global{
              p{
                font-size: 32px;
                line-height: 45px;
              }
            }
          }
          .button_span {
            margin-top: 28px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1600px) {
  .header_banner {
    :global {
      .slider-container {
        aspect-ratio: auto;
        .slider_div {
          img {
            aspect-ratio: 16/8;
            object-fit: cover;
          }
        }
      }
    }
  }
}
