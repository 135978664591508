@import "@styles/core/index.scss";

.container {
  padding: 100px 0 0 0;
  color: $primary;
  :global {
    h3 {
      text-transform: uppercase;
      text-align: center;
      font-family: "brownmedium", serif;
      font-size: 18px;
      line-height: 19.98px;
      letter-spacing: 0.9px;
      padding-bottom: 30px;
    }
    .about-honey, p {
      text-transform: uppercase;
      font-size: 48px;
      line-height: 53.279px;
      letter-spacing: 4.8px;
    }
    .about-honey {
      padding-top: 22px;
      font-family: "brownmedium", serif;
    }
    hr {
      height: 1px;
      background-color: $golden;
      border: none;
    }
    .latest_media {
      margin: 100px 0;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      h3 {
        text-transform: uppercase;
        padding: 0 20px;
        font-size: 18px;
        font-family: "brownmedium", serif;
        line-height: 19.98px;
        letter-spacing: 0.9px;
      }
      hr {
        flex: 1;
      }
    }
    .media_release_container {
      padding: 0 53px 100px 53px;
      display: flex;
      flex-flow: wrap;
      gap: 60px;
      .center-text {
        width: 100%;
        text-align: center;
      }
      .media_item {
        cursor: pointer;
        background: $text-color-white;
        flex: 0 0 29.768%;
        transition: all 0.3s ease;
        a {
          text-decoration: none;
          display: inline-flex;
          flex-direction: column;
          align-items: flex-start;
          aspect-ratio: 1/0.64671;
          font-size: 16px;
          color: $title-secondary;
          padding: 0 40px 0 25px;
          height: 100%;
          p {
            text-align: left;
            text-transform: unset;
            margin-top: 27px;
            font-family: "brownregular", serif;
            font-size: 16px;
            line-height: 25px;
            letter-spacing: 0.01em;
          }
          .date {
            line-height: 18px;
            margin-top: auto;
            margin-bottom: 33.89px;
          }
        }
        &:hover {
          background: #938985;
          a {
            p, .date {
              color: $text-color-white;
            }
          }
        }
      }
    }
    .natural_honey {
      padding: 100px 0;
      .title {
        display: flex;
        gap: 27px;
        flex-direction: column;
        span, h2,h3 {
          font-family: "brownlight", serif;
          font-size: 48px;
          text-transform: uppercase;
          line-height: 53.279px;
          letter-spacing: 4.8px;
          padding-bottom: unset;
        }
        span {
          font-size: 38px;
          font-family: "brownregular", serif;
          line-height: 42.179px;
          letter-spacing: 1.9px;
        }
        .mobile {
          display: none;
        }
      }
      .table {
        display: flex;
        flex-direction: column;
        width: 42%;
        margin: 44px auto 0 auto;
        div {
          display: flex;
          flex-direction: row;
          align-items: center;
          height: 52px;
          text-align: left;

          span {
            padding-left: 30px;
            flex: 1;
            font-size: 16px;
            font-family: "brownregular", serif;
            font-weight: 400;
            line-height: 25.04px;
            letter-spacing: 0.16px;

          }
        }
        .even {
          background-color: $text-color-white;
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .container {
    padding: 60px 0 0 0;
    :global {
      h3 {
        padding-bottom: 30px;
      }
      .about-honey{
        padding-bottom: unset;
      }
      .latest_media {
        margin: 60px 0;
      }
      .media_release_container {
        gap: 40px;
        padding: 0 33px 60px 33px;
        .media_item {
          flex: 0 0 47%;
          a {
            padding: 0 76px 0 25px;
            p {
              font-size: 15px;
              line-height: 20px;
            }
          }
        }
      }
      .natural_honey {
        padding: 60px 0;
        .title {
          gap: 14px;
          h2,h3 {
            font-size: 38px;
          }
          span {
            font-size: 28px;
          }
        }
        .table {
          width: 70%;
          div {
            span {
              &:nth-child(even) {
                padding-left: 30px;
              }
            }
          }
        }
      }

    }
  }
}

@media screen and (max-width: 480px) {
  .container {
    padding: 60px 0 0 0;
    :global {
      h3 {
        padding-bottom: 14px;
        font-size: 16px;
      }
      p, .about-honey {
        font-size: 32px;
        line-height: 35px;
      }
      .about-honey{
        padding-top: 16px;
        padding-bottom: unset;
      }
      .latest_media {
        margin: 60px 0;
        h3 {
          font-size: 16px;
        }
      }
      .media_release_container {
        padding: 0 20px 60px 20px;
        gap: 20px;
        .media_item {
          flex: 0 0 100%;
          aspect-ratio: 1/0.6171;
          a {
            padding: 0 78px 0 25px;
            aspect-ratio: unset;
          }
        }
      }
      .natural_honey {
        padding: 60px 0;
        .title {
          gap: 12px;
          justify-content: center;
          h2,h3 {
            font-size: 24px;
            line-height: 30px;
          }
          span {
            width: 95%;
            margin: auto;
            font-size: 24px;
            line-height: 38px;
          }
          .desktop {
            display: none;
          }
          .mobile {
            display: block;
          }
        }
        .table {
          width: 100%;
          div {
            span {
              padding-left: 20px;
              &:nth-child(even) {
                padding-left: 20px;
              }
            }
          }
        }
      }
    }

  }
}
