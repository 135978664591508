@import "../../core/index";

.about_us {
  position: relative;
  margin-top: 100px;
  margin-bottom: 60px;
  background-color: $secondary;
  overflow: hidden;
  .about_us_container {
    text-align: center;
    display: flex;
    flex-direction: column;
    .titles {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
    .title_bold {
      padding-bottom: 0 !important;
      z-index: 999;
      display: inline-block;
      vertical-align: bottom;
      margin-bottom: 10px;
      padding-top: 20px;
    }
    .title_small {
      font-family: "brownmedium", serif;
      margin-top: 38px;
      padding-top: 0;
      font-size: 16px;
      padding-bottom: 0 !important;
      z-index: 999;
      display: inline-block;
      vertical-align: bottom;
    }
    .dotted_line {
      width: 10%;
      height: 2.5px;
      margin: 30px auto 30px auto;
      background-image: linear-gradient(to right, #D2D2D3 50%, rgba(255, 255, 255, 0) 0%);
      background-position: bottom;
      background-size: 20px 2px;
      background-repeat: repeat-x;
      z-index: 999;
    }
    span {
      width: 50%;
      margin: auto;
      font-size: 16px;
      line-height: 25px;
      color: $primary;
      z-index: 999;
    }
    .btn_learn_more {
      font-family: "brownregular", serif;
      height: 50px;
      margin: 37px auto 55px auto;
      text-decoration: none;
      text-transform: uppercase;
      padding: 0 26.23px;
      background: $text-color-white;
      color: $primary;
      font-size: 15px;
      line-height: 14px;
      letter-spacing: 0.05em;
      align-items: center;
      display: flex;
      cursor: pointer;
      z-index: 999;
      transition: all 300ms linear;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  .images {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: block;
    overflow: hidden;
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    }
    .desktop {
      display: block;
    }
    .ipad {
      display: none;
    }
    .mobile {
      display: none;
    }
  }
}

@media screen and (max-width: 480px) {
  .about_us {
    margin-top: 60px;
    .about_us_container {
      .titles {
        padding-bottom: 0 !important;
      }
      .title_bold {
        padding-bottom: 0 !important;
        line-height: 35.33px;
        width: 60%;
        margin: auto;
      }
      .dotted_line {
        width: 25%;
        margin: 20px auto 20px auto;
      }
      span {
        font-size: 12px;
        width: 88%;
        z-index: 999;
        line-height: 18px;
      }
      .btn_learn_more {
        margin: 25px auto 100px auto;
        height: 35px;
        padding: 0 18.23px;
        font-size: 10px;
        span {
          font-size: 10px;
        }
      }
    }
    .images {
      .desktop {
        display: none;
      }
      .mobile {
        display: block;
      }
    }
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .about_us {
    margin-top: 60px;
    .about_us_container {
      .dotted_line {
        width: 21%;
      }
      span {
        width: 68%;
        z-index: 999;
      }
    }
    .images {
      .desktop {
        display: none;
      }
      .ipad {
        display: block;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .about_us {
    margin-top: 60px;
    .about_us_container {
      .dotted_line {
        width: 19%;
      }
      span {
        width: 68%;
        z-index: 999;
      }
    }
    .images {
      .desktop {
        display: none;
      }
      .ipad {
        display: block;
      }
    }
  }
}

@media only screen and (min-width: 1600px) {
  .about_us {
    .about_us_container {
      .title_bold {
        height: 75px;
      }
    }
  }
}
